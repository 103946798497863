<template>
  <div class="container-fluid my-auto">
    <div class="row d-flex text-center justify-content-around mt-5">
      <h4>SELECCIONE UN TIPO DE PLANIFICACIÓN</h4>
    </div>
    <div class="row d-flex text-center justify-content-around mt-5 pt-3">
      <div class="col p-2 m-2 btn button button-new" @click="$router.push({ name: 'wizard-step-1' })  ">
        <div class="card-body">
            <h4 class="card-title">Crear nuevo plan de aducción</h4>
            <div>
              <p class="card-text" style="color: black; font-size: 0.95rem; font-weight: 500;">
                Pulse esta opción si desea crear un plan de aducción de forma
                guiada.
              </p>
            </div>
          </div>
      </div>
      <div  class="col p-2 m-2 btn button button-load"
            :class="favoritePlanExists? '':'disabled'"
            @click="$router.push({ name: 'favorite-plan' })  ">
        <div class="card-body">
            <h4 class="card-title">Usar plan aducción favorito</h4>
            <div>
              <p class="card-text" style="color: black; font-size: 0.95rem; font-weight: 500;">
                Seleccione este opción si desea recuperar las configuraciones
                del último plan marcado como favorito.
              </p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PrevisionService from "@/services/PrevisionService";

export default {
  data() {
    return {
      favoritePlanExists: false
    };
  },
  components: {},
  computed: {
    ...mapState("planner", []),
  },
  methods: {},
  async mounted() {
    let response01 = await PrevisionService.getFavoritePlanExists();
    this.favoritePlanExists = response01.data;
  },
};
</script>

<style scoped>
.button {
  outline: 2px dashed grey;
  border-radius: 15px;
  height: 300px;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(194, 230, 255);
}
.buttonContent {
  margin: 10px;
}
.button:hover {
  outline: 2px solid black;
  border-radius: 15px;
  background-color: rgb(180, 255, 160);
  box-shadow: 0px 0px 7px rgb(84, 84, 84);
  transform: scale(1.01);
  transition:0.2s;
  transition-timing-function: ease-out;
  
}
.button-new {
  background-image: url("~@/assets/logos/new-plan.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 260px auto;
}
.button-load {
  background-image: url("~@/assets/logos/load-plan.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 300px auto;
}
.disabled{
  opacity: 0.4;
}

.my-auto{
  margin-top: auto;
margin-bottom: auto;
}
</style>